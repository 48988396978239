//---------------------------------------------
//
//  Fonts
//
//    1. Loading Mixins
//    2. Loading Fonts
//
//---------------------------------------------

//---------------------------------------------
// 1. Loading Mixins
//---------------------------------------------

@import "mixins/google-fonts";

//---------------------------------------------
// 2. Loading Fonts
//---------------------------------------------



/*
 * Example for loading multiple fonts at once
 *
@include googleFonts((
  (
    "name": "Roboto",
    "folder": "Roboto",
    "files": "Roboto",
    "weights": (300, 400, 700)
  ),
  (
    "name": "Besley",
    "folder": "Besley",
    "files": "Besley",
    "weights": (300, 400, 700)
  ),
));
*/

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;400&display=swap');

@font-face {
  font-family: "agatho";
  src: url('../../../../public/images/fonts/agatho/Agatho_Regular.otf')  format('truetype');
}

.font-family-agatho{
  font-family: "agatho";
}

//font colors
.font-color-neutral-black {
  color: #0B1019;
}

@include media-breakpoint-down(sm) {
  .font-color-neutral-white {
    color: #FFFFFF !important;
  }
}

.font-color-grey-new {
  color: #666666;
}

.font-color-grey-medium {
  color: #85878C;
}

.font-color-grey-dark {
  color: #34383F;

}

.font-color-grey-light {
  color: #AEAFB2;
}

.font-color-background-grey {
  color: #F2F2F2;
}

.font-color-white {
  color: #FFFFFF;
}

.font-color-beige-light {
  color: #FAFAED;
}

.font-color-new-beige-lightest {
  color: #EDE1D5;
}

.font-color-new-beige-lighter {
  color: #E6CCB3;
}

.font-color-beige-dark {
  color: #3B2713;
}

.font-color-beige-new-darker {
  color: #9B6643;
}

.font-color-black {
  color: #000000;
}

.font-color-linear-gradient {
  background: linear-gradient(180deg, #AB957D 0%, #0B1019 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.font-color-linear-gradient-about {
  background: linear-gradient(180deg, rgba(158, 100, 61, 0.4) 0%, #6A3816 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

//font-sizes

.font-size-48 {
    font-size: 48px;
}

@include media-breakpoint-down(sm){
  .font-size-48{
    font-size: 32px;
  }
}



.font-size-18 {
  font-size: 18px;
}

@include  media-breakpoint-down(sm) {
  .font-size-18 {
    font-size: 16px;
  }
}

.font-size-12 {
  font-size: 12px;
}

.font-size-42 {
  font-size: 42px;
}

@include media-breakpoint-down(sm) {
  .font-size-42 {
    font-size: 24px;
  }
}

.font-size-32 {
  font-size: 32px;
}

@include media-breakpoint-down(sm) {
  .font-size-32 {
    font-size: 20px;
  }
}

.font-size-24 {
  font-size: 24px;
}

@include media-breakpoint-down(sm) {
  .font-size-24 {
    font-size: 16px;
  }
}

//font-weights

.font-weight-300p {
  font-weight: 300;
}

.font-weight-400p {
  font-weight: 400;
}

.font-weight-500p {
  font-weight: 500;
}

.font-weight-700p {
  font-weight: 700;
}

.font-weight-600p {
  font-weight: 600;
}
