.button-bg {
  background-color: #AB957D;
  border: none;
  border-radius: 30px;
  padding: 12px 24px 12px 24px;
}

@include media-breakpoint-down(sm){
  .button-bg {
    padding: 8px 16px 8px 16px;
    border-radius: 30px;
    gap: 8px;
    background-color: #AB957D;
  }
}

//.button-contact {
//  border: none;
//  border-radius: 30px;
//  padding: 12px 24px 12px 24px;
//}

.button-book-consult {
  background-color: #AB957D;
  border: none;
  border-radius: 50px;
  padding: 8px 16px 8px 16px;
}

.button-explore-all {
  background-color: #FAFAED;
  border: none;
  border-radius: 30px;
  padding: 12px 24px 12px 24px;
}


.button-right-left-arrow {
  background-color: #AB957D;
  border: none;
  border-radius: 50%;
  padding: 16px;
  gap: 8px;
}

@include media-breakpoint-down(sm) {
  .button-right-left-arrow {
    padding: 8px;
  }
}

.button-contact-us {
  background-color: #7E5439;
  border: none;
  border-radius: 30px;
  padding:12px 24px 12px 24px;
  gap: 10px;
}


//services

.button-services-bg {
  padding: 8px 12px 8px 12px;
  border-radius: 30px;
  gap: 10px;
  background-color: #DCB992;
}


.button-services-left-right {
  background-color: #E6CCB3;
  border-radius: 50px;
  border: none;
}


//gallery

.button-gallery-bg {
  padding: 8px 12px 8px 12px;
  border-radius: 30px;
  background-color: #FFFFFF;
}


.btn-service-active-tab {
  padding: 8px 12px 8px 12px;
  border-radius: 30px;
  background-color: #DCB992;
}

.btn-service-tab {
  padding: 12px 8px 12px 8px;
}
