
html, body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

//nav bar

.nav-bar-container {
  border-radius: 78px;
  box-shadow: 0px 10px 40px 0px #AB957D26;
}

.font-family-manrope{
  font-family: 'Manrope', sans-serif ;
}

//body{
//  @extend .font-family-besley;
//}

.border-none {
  border: none;
}

.contact-us-button {
  background-color: #2DB6E9;
  color: #FFFFFF;
  border-radius: 30px;
}

.font-color-grey-2 {
  color: #666666;
}

.font-size-14 {
  font-size: 14px;
}
//
//@include media-breakpoint-down(sm) {
//  .font-size-14 {
//    font-size: 12px;
//  }
//}


//hero-section

.hero-content-container {
  padding-left: 50px;
}

.clients-meeting-card-container {
  border-radius: 5px;
  box-shadow: 0px 10px 40px 0px #AB957D26;
}

//our services

//.our-services-wedding-card-container {
//  background-image: url("../images/landing/wedding-image-with-hall.png");
//  background-size: cover;
//  background-repeat: no-repeat;
//  height: 200px;
//  border: 10px solid #FFEBD6;
//  border-radius: 10px;
//}
//
//.our-services-birthday-party-card-container {
//  background-image: url("../images/landing/wedding-image-with-hall.png");
//  background-size: cover;
//  background-repeat: no-repeat;
//  height: 200px;
//  border: 10px solid #FFEBD6;
//  border-radius: 10px;
//}
//
//.our-services-corporate-events-card-container {
//  background-image: url("../images/landing/wedding-image-with-hall.png");
//  background-size: cover;
//  background-repeat: no-repeat;
//  height: 200px;
//  border: 10px solid #FFEBD6;
//  border-radius: 10px;
//}
//
//.our-services-movie-promotions-card-container {
//  background-image: url("../images/landing/movie-theater-with-audience.png");
//  background-size: cover;
//  background-repeat: no-repeat;
//  height: 200px;
//  border: 10px solid #FFEBD6;
//  border-radius: 10px;
//}

//.our-services-card-container {
//
//}

.our-services-container {
  padding: 56px 160px 56px 160px;
}

.our-services-card-header-content {
  background: #FFFFFF4D;
  border-radius: 10px;
  border: none;
}

.wedding-images-container {
  border-radius: 50%;
  border: none;
}


.display-content {
  display: block !important;
}

.display-none-content {
  display: none !important;
}

//client-review-container

.review-card-container {
  border: 1px solid #AB957D;
  border-radius: 10px;
}

//.your-one-stop-solution-container {
//  background-image: linear-gradient(to top, #FFFFFF80, #FFFFFF80 50%), url("../images/landing/your-one-stop-sollution-bg-image.png");
//  background-size: cover;
//  background-repeat: no-repeat;
//  box-shadow: 0px 0px 30px 16px #AB957D14;
//  border: none;
//  border-radius: 20px;
//  padding: 56px 0px 56px 0px;
//  gap: 8px;
//
//}


//footer container
.footer-bg-container {
  background: #343434;
}


//flipping

//.flip-card {
//  background-color: transparent;
//  width: 300px;
//  height: 300px;
//  perspective: 1000px;
//}
//
//.flip-card-inner {
//  position: relative;
//  width: 100%;
//  height: 100%;
//  text-align: center;
//  transition: transform 0.6s;
//  transform-style: preserve-3d;
//  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
//  backface-visibility: hidden;
//  -moz-backface-visibility: hidden;
//}
//
//.flip-card:focus {
//  outline: 0;
//}
//
//.flip-card:hover .flip-card-inner,
//.flip-card:focus .flip-card-inner{
//  transform: rotateY(180deg);
//}
//
//.flip-card-front,
//.flip-card-back {
//  position: absolute;
//  width: 100%;
//  height: 100%;
//}
//
//.flip-card-front {
//  //background: linear-gradient(to left, #4364f7, #6fb1fc);
//  //color: black;
//  z-index: 2;
//  display: flex;
//  justify-content: center;
//  align-items: center;
//}
//
//.flip-card-back {
//  //background: linear-gradient(to right, #4364f7, #6fb1fc);
//  //color: white;
//  transform: rotateY(180deg);
//  z-index: 1;
//  display: flex;
//  justify-content: center;
//  align-items: center;
//}
//h3 {
//  font-size: 20px;
//  font-family: Verdana, sans-serif;
//  font-weight: bold;
//  color: #fff;
//}
//
//
.team-member-card {
  position:relative;
}

.team-member-card:hover{
  padding-bottom: 48px !important;
  text-align: center !important;
  display: flex;
  flex-direction: column;
  align-items: center !important;
  .team-member-card-img{
    transform: scale(0.7) !important;
    transition-duration: 1s !important;


  }

  .team-member-description{
    opacity: 1 !important;
    bottom: 0.1px;
    transition-duration: 1s !important;
    animation: slideUp 1s ease forwards !important;
  }
}
@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
.team-member-description {
  position: absolute;
}

.team-member-description label {

}

//flipping-2

.card-flip {
  perspective: 1000px;
  &:hover .flip,
  &.hover .flip {
    transform: rotateY(180deg);
  }
  background-color: transparent;
}

//
.card {
  //background-color: transparent;
  border: none;
}
//
//
.card-flip,
.front,
.back {
  //width: 100%;
  //height: 100%;
}
//
.flip {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
}

.front,
.back {
  backface-visibility: hidden;
  //position: absolute;
  //top: 0;
  //left: 0;
}

.front {
  z-index: 2;
  transform: rotateY(0deg);
  height: 0;
}

.back {
  transform: rotateY(180deg);
}

.provider-bg-image {
  background-size: cover;
  height: 60vh;
  background-repeat: no-repeat;
  background-position: center;
}

.provider-flip-bg-image {
  background-image: url('../../../../public/images/services/bg-provide-image.png');
  background-size: cover;
  background-repeat: no-repeat;
  height: 60vh;
  background-position: center;
}

@include media-breakpoint-down(sm) {
  .provider-bg-image {
    height: 40vh;
  }
}

@include media-breakpoint-down(sm) {
  .provider-flip-bg-image {
    height: 40vh;
  }
}

//.provider-swiper .swiper-slide {
//  height: auto;
//}

.provider-swiper {
  height: 100%;
}

.provider-swiper-slide {
  height: auto !important;
}

.swiper-content {
  height: 100% !important;
}



//contact-us

.form-container {
  border-width: 1px 1px 0px 1px;
  border-style: solid;
  border-radius: 10px 10px 0px 0px;
  border-color: #FFFFFF;
  padding: 24px;
  //background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5));

}

::placeholder {
  color: #666666;
}


//gallery

.image-radius {
  border-radius: 10px;
}




//.ring-rose-image-container {
//  background-image: url("../images/landing/groom-and-bride-images-with-wedding-dresses.png");
//  background-size: cover;
//  background-repeat: no-repeat;
//}

.background-image {
  height: 50vh;
  width: 50vw;
  border-radius: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  animation: changeBackground 10s infinite linear; /* Change background every 10 seconds */
}

//@keyframes changeBackground {
//  0% {
//    background-image: url('../images/services/bride-image-with-red-dress.png'); /* Path to your first image */
//  }
//  50% {
//    background-image: url('../images/services/couple-hands-pinky-promise.png'); /* Path to your second image */
//  }
//  100% {
//    background-image: url('../images/services/couple-marriage-image.png'); /* Path to your third image */
//  }
  //75% {
  //  background-image: url('image4.jpg'); /* Path to your fourth image */
  //}
  //100% {
  //  background-image: url('image1.jpg'); /* Repeat with the first image */
  //}
//}

.nav-pills .nav-link.active,

.nav-pills .nav-item.show .nav-link {
  color: #000000;
  padding: 8px 12px 8px 12px;
  border-radius: 30px;
  gap: 10px;
  background-color: #DCB992;
}

//.nav-pills .nav-link,
//.nav-pills .nav-item.show .nav-link {
//}


.nav-tabs {
  border: none;
}

.nav-tabs .nav-link,
.nav-tabs .nav-item.show .nav-link {
  padding: 8px 12px 8px 12px;
  border-radius: 30px;
  gap: 10px;
  background-color: #FFFFFF;
  border: none;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #7E5439;
  padding: 8px 12px 8px 12px;
  border-radius: 30px;
  gap: 10px;
  background-color: #DCB992;
  border: none;
}



// media quires

@include media-breakpoint-down(sm) {
  .transform-your-vision-container {
    text-align: center !important;
    //color: #FFFFFF !important;
  }
}


.image-border {
  border: 3px solid black;
}


.display-block {
  display: block !important;
}

.display-none {
  display: none !important;
}

.service-image-radius {
  border-radius: 100%;
  border: 30px solid #FFFFFF1A;

}
