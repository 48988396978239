//hero section

.bg-hero-section-container {
  background-image: linear-gradient(to top, #00000099, #00000099), url("../../../../public/images/landing/hero-section-bg-image.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

}

.bg-your-one-stop-solution-container {
  background-image: url("../../../../public/images/landing/bg-one-stop-image.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  border-radius: 20px;
  padding: 56px 0px 56px 0px;
  gap: 8px;
  box-shadow: 0px 0px 30px 16px #AB957D14;
}

@include  media-breakpoint-down(sm) {
  .bg-your-one-stop-solution-container {
    padding: 40px 16px 40px 16px;
  }
}

//our brands section

//.our-brands-container{
//  background: linear-gradient(90deg, #3B2713 0%, rgba(59, 39, 19, 0) 100%);
//}


//our-clients

.bg-our-clients-to-gallery {
  background: #F2F2F2;
}

.clients-bg-dark-image-container {
  background-color: #3B2713;
  width: 543px;
  height: 543px;
  border-radius: 5px;

}

.client-bg-image-container {
  //background-image: url("../../../../public/images/landing/how-we-work-bg-image.png");
  //background-size: cover;
  //background-repeat: no-repeat;
  //border-radius: 5px;
  margin-top: -350px;
  margin-left: -10px;
}

.clients-meeting-to-execution-container {
  position: relative;
  border-radius: 5px;
  gap: 10px;
  //background: #FFFFFF;
  margin-left: -100px;
  top: 70px;
}

@include media-breakpoint-down(sm) {
  .clients-meeting-to-execution-container {
    margin-left: 0;
    top: 0;
  }
}


// our-services

.bg-navbar {
  padding-left: 80px;
  padding-right: 80px;
}

.bg-our-services {
  background-color: #FFFFFF;
  border-radius: 40px;
  border: none;
  padding: 56px 160px 56px 160px;
}

.our-services-card-container {
  //background-color: transparent;
  border-radius: 150px;
  //border: 1px solid #FFFFFF;
  //background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
  background-color: #E7E2DA;

}

@include media-breakpoint-down(sm){
  .our-services-card-container{
    border-radius: 20px;
    text-align: center;
  }
}

@include media-breakpoint-down(sm) {
  .text-align-center {
    text-align: center;
  }
}

// our gallery

.our-gallery-container {
  padding: 56px 80px 56px 80px;
}

.preview-image-container {
  background-image: url("../../../../public/images/landing/rings-and-flowers-image.png");
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 20px;
}


//our clients review container

.our-clients-review-bg-container {
  background-image: url("../../../../public/images/landing/our-clients-review-bg-image.png");
  background-size: cover;
  background-repeat: no-repeat;
}


//footer container

.footer-bg-container {
  background: #3B2713;

}

.designed-by-container {
  background-color: #FFEBD6;
}


// service details page

// hero section

.bg-hero-service-container {
  //background-image: url('../../../../public/images/services/bg-image-services-hero-section-blue-flash.png'),url('../../../../public/images/services/service-bg-image.png');
  background-size: contain, cover; /* Adjust as per your image size */
  background-position: 77% 100%; /* Adjust as per your requirements */
  background-repeat: no-repeat;
  object-fit: cover;
}

@include media-breakpoint-down(sm) {
  .bg-hero-service-container {
    background-image: none;
  }
}

.bg-services-container {
  margin-top: -50px;
}

.bg-service-container {
  box-shadow: 0px 10px 40px 0px #AB957D26;
  border-radius: 10px;
}


.bg-our-services-container {
  background-color: #EDE1D5;
}


.bg-gallery-show-container {
  background-image: url("../../../../public/images/services/bg-gallery-show.png");
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  border: none;
}


//.couple-images-container {
//  background-image: url("../../../../public/images/services/couple-image-with-wedding-dress.png");
//  background-size: cover;
//  background-repeat: no-repeat;
//  border-radius: 20px;
//  width: 25%;
//  height: 50%;
//}


//About page

.bg-hero-about-container {
  background-image: url("../../../../public/images/about/bg-about-image.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-about-choose-us {
  background-color: #F2F2F2;
}

.bg-about-us-container {
  padding-left: 355px;
  padding-right: 355px;
}

.bg-about-container {
  padding: 32px 50px 32px 50px;
  border-radius: 5px;
  border: none;
  box-shadow: 0px 10px 40px 0px #AB957D26;
  margin-top: -80px;
}

@include media-breakpoint-down(sm) {
  .bg-about-container {
    margin-top: -50px;
  }
}

.bg-expertise-across-diverse-events-card {
  background-color: #EDE1D5;
  border-radius: 10px;
}

.bg-creative-vision-card {
  background-color: #E6CCB3;
}

.bg-client-approach-card {
  background-color: #DCB992;
}

.bg-personalized-service-card {
  background-color: #FFEBD6;
}


//contact us

.bg-contact-us-hero-section {
  background: #FAFAED url('../../../../public/images/contact-us/bg-contact-us-hero-section.png') top right no-repeat; /* Adjust as per your requirements */
  background-size: 55% 100%;
}

@include media-breakpoint-down(sm) {
  .bg-contact-us-hero-section {
    background-size: cover, cover;
    background-position: center;
    background-color: transparent;
  }
}

.bg-contact-us-container-left {
  background-color: #FAFAED;
}

.bg-your-vision-mission-card {
  padding: 8px 20px 8px 20px;
  border-radius: 50px;
  gap: 10px;
  background: #9B664333;
}

@include media-breakpoint-down(sm) {
  .bg-your-vision-mission-card {
    color: #FFFFFF !important;
  }
}

.bg-contact-us-container {
  background-color: #FAFAED;
}

.bg-one-step-and-location-container {
  padding: 56px 160px 56px 160px;
}

.bg-input-field {
  background-color: #EDE1D5;
  border-radius: 10px;
  padding: 20px 16px 20px 16px;
}


//gallery page

.bg-gallery-hero-section {
  background-image: url("../../../../public/images/gallery/bg-gallery-hero-section-image.png");
  background-size: cover;
  background-repeat: no-repeat;
 }

.bg-gallery-services-container {
  background: #FFFFFF4D;
  border: 1px solid #FFFFFF4D;
  border-radius: 10px;
}

@include media-breakpoint-down(sm) {
  .bg-gallery-services-container {
    border-radius: 0px;
  }
}

.bg-gallery-container {
  background-color: #f2f2f2;
}

.bg-gallery-hero-container {
  padding-left: 160px;
  padding-right: 160px;
}


.client-images-parent {
  position: relative;
  top: 0;
  left: 0;
}

.bg-dark-image {
  position: relative;
  top: 30px;
  left: 30px;
}

@include media-breakpoint-down(sm) {

  .bg-dark-image {
    display: none;
  }
}

.bg-client-image {
  position: absolute;
  top: 0px;
  left: 0px;
}

@include media-breakpoint-down(sm) {
  .bg-client-image {
    position: static;
  }
}


//media quires

//index page

@media screen and (max-width: 600px){

  //.our-services-card-container {
  //  border-radius: 20px;
  //  text-align: center;
  //

  .media-font-size-20 {
    font-size: 20px;
  }

  .media-font-size-12 {
    font-size: 12px;
  }

  .media-font-size-16 {
    font-size: 16px;
  }

}

@include media-breakpoint-down(sm) {
  .footer-details-container {
    display: flex;
    flex-direction: column !important;
  }
}

@include media-breakpoint-down(sm) {
  .res-display-none {
    display: none !important;
  }
}

@include media-breakpoint-down(sm) {
  .res-display-block {
    display: block !important;
  }
}

//@include media-breakpoint-down(sm) {
//
//}


@include media-breakpoint-down(sm) {
  .bg-service-container {
    box-shadow: none;
    border-radius: 0px;
  }
}

@include media-breakpoint-down(sm) {
  .bg-services-container {
    margin-top: 0;
  }
}


@include media-breakpoint-down(sm) {
  .bg-hero-service-container {
    background-image: url('../../../../public/images/services/service-bg-image.png');
    background-size:cover; /* Adjust as per your image size */
    background-repeat: no-repeat;
  }
}

@include media-breakpoint-down(sm) {
  .media-button-right-left-arrow {
    background-color: transparent;
    color: #AB957D;
  }
}

@include media-breakpoint-down(sm) {
  .footer-logo {
    display: flex;
    justify-content: start !important;
  }
}

@include media-breakpoint-down(sm) {
  .gallery-show-container {
    display: flex;
    flex-direction: column !important;
    justify-content: space-evenly;
  }
}
